import React, { Component } from 'react';
import "./styles.sass";

class Loading extends Component {
    render() {
        return (
					<div className={"loading-shimmer" + (this.props.classes ? " " + this.props.classes : "")} style={this.props.styles}>
							&#8203;
					</div>
        )
    }
}

export default Loading;
