import actionTypes from './ActionTypes';

const updateLaunchNext = (data) => dispatch => {
  dispatch({
    type: actionTypes.LAUNCH_NEXT_UPDATE,
    payload: { data }
  });
}

const updateLaunchesPast = (data) => dispatch => {
  dispatch({
    type: actionTypes.LAUNCHES_PAST_UPDATE,
    payload: { data }
  });
}

const updateLaunchesFuture = (data) => dispatch => {
  dispatch({
    type: actionTypes.LAUNCHES_FUTURE_UPDATE,
    payload: { data }
  });
}

const updateLauchpads = (data) => dispatch => {
  dispatch({
    type: actionTypes.LAUNCHPADS_UPDATE,
    payload: { data }
  });
}

const updateRockets = (data) => dispatch => {
  dispatch({
    type: actionTypes.ROCKETS_UPDATE,
    payload: { data }
  });
}

const updatePayloads = (data) => dispatch => {
  dispatch({
    type: actionTypes.PAYLOADS_UPDATE,
    payload: { data }
  });
}

const updateCores = (data) => dispatch => {
  dispatch({
    type: actionTypes.CORES_UPDATE,
    payload: { data }
  });
}

export {
  updateLaunchNext,
  updateLaunchesPast,
  updateLaunchesFuture,
  updateLauchpads,
  updateRockets,
  updatePayloads,
  updateCores
}
