import React, { Component } from 'react';
import { NavLink, Link } from "react-router-dom";
import "./styles.sass";

class Menu extends Component {
  render() {
		return (
			<div className="menu sticky-top">
				<nav className="nav nav-pills d-block text-center">
					<NavLink className="nav-item nav-link" exact={true} to="/">
						home
					</NavLink>
					<NavLink className="nav-item nav-link" exact={true} to="/launches/future">
						future
					</NavLink>
					<NavLink className="nav-item nav-link" exact={true} to="/launches/past">
						past
					</NavLink>
					<Link className="nav-item nav-link" target="_blank" to="//teespring.com/en-GB/stores/charlesomer">
						shop
					</Link>
					<NavLink className="nav-item nav-link" exact={true} to="/about">
						about
					</NavLink>
				</nav>
			</div>
		)
  }
}
export default Menu;
