import {applyMiddleware, compose, createStore} from "redux";
import { createLogger } from 'redux-logger'

// Root level reducer for our redux data store
import thunk from "redux-thunk";
import RootReducer from "./RootReducer";

const middlewares = [thunk];

if (process.env.NODE_ENV === 'development') {
	const logger = createLogger({
		collapsed: true
	});

	middlewares.push(logger);
}

export default compose(applyMiddleware(...middlewares))(createStore)(RootReducer);
