import { createReducer } from '../ReducerHelpers';
import actionTypes from '../ActionTypes';

const initialState = {
  next: {
    loaded: false,
    data: {
      launch_date_unix: 0,
      mission_name: "Loading...",
      launch_site: {
        site_name_long: "Loading..."
      },
      rocket: {
        rocket_name: "Loading..."
      }
    }
  },
  past: {
    loaded: false,
    data: []
  },
  future: {
    loaded: false,
    data: []
  },
  launchpads: {
    loaded: false,
    data: []
  },
  rockets: {
    loaded: false,
    data: []
  },
  payloads: {
    loaded: false,
    data: []
  },
  cores: {
    loaded: false,
    data: []
  }
}

export default createReducer(initialState, {
  [actionTypes.LAUNCH_NEXT_UPDATE]: (state, action) => (
    {
      ...state,
      next: {
        ...state.next,
        data: action.payload.data,
        loaded: true
      }
    }
  ),
  [actionTypes.LAUNCHES_FUTURE_UPDATE]: (state, action) => (
    {
      ...state,
      future: {
        ...state.next,
        data: action.payload.data,
        loaded: true
      }
    }
  ),
  [actionTypes.LAUNCHES_PAST_UPDATE]: (state, action) => (
    {
      ...state,
      past: {
        ...state.next,
        data: action.payload.data,
        loaded: true
      }
    }
  ),
  [actionTypes.LAUNCHPADS_UPDATE]: (state, action) => (
    {
      ...state,
      launchpads: {
        ...state.next,
        data: action.payload.data,
        loaded: true
      }
    }
  ),
  [actionTypes.ROCKETS_UPDATE]: (state, action) => (
    {
      ...state,
      rockets: {
        ...state.next,
        data: action.payload.data,
        loaded: true
      }
    }
  ),
  [actionTypes.PAYLOADS_UPDATE]: (state, action) => (
    {
      ...state,
      payloads: {
        ...state.next,
        data: action.payload.data,
        loaded: true
      }
    }
  ),
  [actionTypes.CORES_UPDATE]: (state, action) => (
    {
      ...state,
      cores: {
        ...state.next,
        data: action.payload.data,
        loaded: true
      }
    }
  ),
  [actionTypes.MORE_INFO_UPDATE]: (state, action) => (
    {
      ...state,
      moreInfo: {
        ...state.next,
        isLoaded: action.payload.data,
      }
    }
  ),
});
