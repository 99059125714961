import React, { Component } from 'react';
import Countdown from 'react-countdown-now';
import {Collapse} from 'react-collapse';
import {FaAngleUp} from 'react-icons/fa';
import {FaAngleDown} from 'react-icons/fa';
import {connect} from "react-redux";
import Loading from "../../images/Eclipse-1.5s-200px.gif";
import { CSSTransition } from 'react-transition-group';
import "./styles.sass";

class Home extends Component {
	constructor(props) {
		super(props);
		this.state = {
				moreInfo: false
		};
	}

	componentDidMount() {
		this.props.updateTitle("SpaceX Launch Countdown");
	}

	toggleMoreInfo() {
		this.setState({ moreInfo: !this.state.moreInfo });
	}

	render() {		
		var dateObj = new Date(this.props.launchNext.data.date_utc);
		var dateObjText = dateObj.toString();

		let txtLaunchpad = "Loading launchpad...";
		if (this.props.launchpads.loaded && this.props.launchNext.loaded) {
			txtLaunchpad = this.props.launchpads.data.find(x => x.id === this.props.launchNext.data.launchpad).name
		}
		let txtRocket = "Loading rocket...";
		if (this.props.rockets.loaded && this.props.launchNext.loaded) {
			txtRocket = this.props.rockets.data.find(x => x.id === this.props.launchNext.data.rocket).name
		}

		return (
			<div className="container-fluid">
				<div className={"row position-absolute loading fade mx-auto " + (this.props.launchNext.loaded ? 'hide' : 'show')} >
					<div className="col">
						<img className="w-25" src={Loading} alt="loading..." />
					</div>
				</div>

				<div className={"row fade mx-auto" + (this.props.launchNext.loaded ? ' show' : ' hide')}>
					<div className="col">
						<div className="row">
							<div className="col">
								<span style={{"fontSize": "0.7rem"}}>{ this.props.launchNext.data.tbd ? "no earlier than" : "" }</span>
								<div className="countdown">
									<Countdown date={dateObj} /> {/* year, month (0-11), day, hours, minutes, seconds, milliseconds */}
								</div>
							</div>
						</div>

						<div className="row mb-3" style={{fontSize: "0.7rem"}}>
							<div className="col">
								days : hours : minutes : seconds
							</div>
						</div>

						<div className="row align-top" style={{fontSize: "0.7rem"}}>
							<div className="col-md-10 offset-md-1">
								<Collapse className="more-info" isOpened={this.state.moreInfo}>
									<div className="row">
										<div className="col-sm-6">
											<u>Mission</u><br />
											{this.props.launchNext.data.name}
										</div>
										<div className="col-sm-6">
											<u>Rocket</u><br />
											{txtRocket}
										</div>
									</div>
									<div className="row">
										<div className="col-sm-6">
											<u>{ this.props.launchNext.data.tbd ? "No Earlier Than" : "Target Time" }</u><br />
											{dateObjText}
										</div>
										<div className="col-sm-6">
											<u>Launch Site</u><br />
											{txtLaunchpad}
										</div>
									</div>
								</Collapse>
								<div className="arrow" onClick={() => this.toggleMoreInfo()}>
									<CSSTransition
										in={this.state.moreInfo}
										timeout={300}
										classNames="arrow-fade"
									>
										<div className="arrow-fade up">
											<FaAngleUp />
										</div>
									</CSSTransition>
									<CSSTransition
										in={!this.state.moreInfo}
										timeout={300}
										classNames="arrow-fade"
									>
										<div className="arrow-fade">
											<FaAngleDown />
										</div>
									</CSSTransition>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		launchNext: state.launchData.next,
		launchpads: state.launchData.launchpads,
		rockets: state.launchData.rockets
	}
}

export default connect(mapStateToProps, undefined)(Home)
