import React, { Component } from 'react';
import { Route } from "react-router-dom";
import Future from "./Future";
import Past from "./Past";
import "./styles.sass";

class Launches extends Component {
	render() {
		return (
			<div className="container launches">
				<div className="table-responsive">
					<Route exact path="/launches/past" render={() => <Past updateTitle={(text) => this.props.updateTitle(text)} />} />
					<Route exact path="/launches/future" render={() => <Future updateTitle={(text) => this.props.updateTitle(text)} />} />
				</div>
			</div>
		)
	}
}

export default Launches;
