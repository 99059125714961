import React, { Component } from 'react';
import { Link } from "react-router-dom";
import Emoji from "../Global/Emoji";
import "./styles.sass";

class About extends Component {
	componentDidMount() {
		this.props.updateTitle("About");
	}

	render() {
		return (
			<div className="container about">
				<div className="row">
					<div className="col">
						<h3>
							About
						</h3>
						<p>
							This site runs solely from donations, partnerships and advertising.
							<br />
							If you would like to support SpaceX Data.Info, please contact me below. <Emoji symbol="🚀😊" label="Rocket."/>
						</p>
						<hr />
						<h3>
							Developed By
						</h3>
						<p>
							Charles Omer
							<br />
							<Link to="//www.charlesomer.co.uk" target="_blank">
								www.charlesomer.co.uk
							</Link>
							<br />
							<Link to="//www.zyndex.co.uk" target="_blank">
								www.zyndex.co.uk
							</Link>
						</p>
						<form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
							<input type="hidden" name="cmd" value="_s-xclick" />
							<input type="hidden" name="hosted_button_id" value="4R22VQH8FDJQ8" />
							<input type="image" src="https://www.paypalobjects.com/en_GB/i/btn/btn_donate_SM.gif" border="0" name="submit" alt="PayPal – The safer, easier way to pay online!" />
							<img alt="" border="0" src="https://www.paypalobjects.com/en_GB/i/scr/pixel.gif" width="1" height="1" />
						</form>
					</div>
				</div>
			</div>
		)
	}
}

export default About;
