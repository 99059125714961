import React, { Component } from 'react';
import { connect } from "react-redux";
import Loading from "../../Global/Loading";
import {Collapse} from 'react-collapse';
import Emoji from "../../Global/Emoji";
import Countdown from 'react-countdown-now';
var moment = require('moment');

class Future extends Component {
  constructor(props) {
    super(props);

    this.state = {
      openedLaunch: false
    }
  }

  componentDidMount() {
    this.props.updateTitle("Future Launches");
  }

  toggleOpenedLaunch(flightNumber) {
    if (this.state.openedLaunch === flightNumber) {
      this.setState({
        openedLaunch: false
      });
    } else {
      this.setState({
        openedLaunch: flightNumber
      });
    }
  }

  render() {
    let launches = [];

    if (
      this.props.launchesFuture.loaded &&
      this.props.launchpads.loaded &&
      this.props.rockets.loaded &&
      this.props.payloads.loaded &&
      this.props.cores.loaded
    ) {
      launches = this.props.launchesFuture.data.sort(function(a, b){return a.date_unix - b.date_unix}).map((launch) => {
        let launchRocket = this.props.rockets.data.find(x => x.id === launch.rocket)

        let launchCores = "No core details yet."
        if (launch.cores.length > 0) {
          launchCores = launch.cores.map((core, index) => {
            let additionalCoreDetails = {
              block: "Unknown.",
              status: "Unknown.",
              last_update: "Unknown."
            };
            if (core.core) {
              additionalCoreDetails = {
                ...additionalCoreDetails,
                ...this.props.cores.data.find(x => x.id === core.core)
              }
            }

            let txtReused = "Unknown."
            if (core.reused) {
              txtReused = "Yes."
            } else if (core.reused === false) {
              txtReused = "No."
            }

            return (
              <React.Fragment key={index}>
                <h6 className="font-italic">
                  Core {index + 1}
                </h6>
                <div className="row mb-2" key={index + 1}>
                  <div className="col">
                    <div>Block {additionalCoreDetails.block}<br /></div>
                    <div>Status: {additionalCoreDetails.status}<br /></div>
                    <div>Last update: {additionalCoreDetails.last_update}</div>
                    <div>Reused: {txtReused}<br /></div>
                    <div>Flight number: {core.flight ? core.flight : "Unknown."}</div>
                  </div>
                </div>
              </React.Fragment>
            )
          });
        }

        let payloads = launch.payloads.map((payloadID, index) => {
          let payload = {
            name: "Unknown.",
            type: "Unknown.",
            reused: "Unknown.",
            orbit: "Unknown"
          }
          payload = {
            ...payload,
            ...this.props.payloads.data.find(x => x.id === payloadID)
          }

          let customers = "Unknown."
          if (payload.customers.length > 0) {
            customers = payload.customers.map((customer, index2) => {
              return (
                <React.Fragment key={index2}>
                  {customer}{(index < payload.customers.length - 1) ? ", " : ""}
                </React.Fragment>
              );
            });
          }

          let nationalities = "Unknown."
          if (payload.nationalities.length > 0) {
            nationalities = payload.nationalities.map((customer, index2) => {
              return (
                <React.Fragment key={index2}>
                  {customer}{(index < payload.nationalities.length - 1) ? ", " : ""}
                </React.Fragment>
              );
            });
          }

          return (
            <div className="row mb-3" key={index}>
              <div className="col">
                <h6 className="font-italic">
                  Payload {index + 1}<br />
                </h6>
                Name: {payload.name}<br />
                Type: {payload.type}<br />
                Orbit: {payload.orbit}<br />
                Reused: {payload.reused ? "Yes." : "No."}<br />
                Customer(s): {customers}<br />
                Nationalities: {nationalities}
              </div>
            </div>
          );
        });

        let countdown = (
          <Countdown date={new Date(launch.date_utc)} /> /* year, month (0-11), day, hours, minutes, seconds, milliseconds */
        )
        let datetime = (
          <React.Fragment>
            {new Date(launch.date_utc).toLocaleDateString()}
            <br />
            {new Date(launch.date_utc).toLocaleTimeString()}
          </React.Fragment>
        )

        if (launch.tbd) {
          countdown = "TBD";

          datetime = (
            <React.Fragment>
              <font size="1">Estimated NET</font><br />
              {new Date(launch.date_utc).toLocaleDateString()}
            </React.Fragment>
          )
        }

        let isOpened = false;
        if (this.state.openedLaunch === launch.flight_number) {
          isOpened = true;
        }

        return (
          <React.Fragment key={launch.flight_number}>
            <tr className="launchRow" style={isOpened ? {'background': '#212529'} : {}} onClick={() => { this.toggleOpenedLaunch(launch.flight_number) }}>
              <td>
                {countdown}
              </td>
              <td>
                {datetime}
              </td>
              <td>
                {launch.name}
              </td>
              <td>
                {launchRocket.name}
              </td>
              <td>
                {this.props.launchpads.data.find(x => x.id === launch.launchpad).name}
              </td>
            </tr>
            <tr>
              <td colSpan="5" className="launchDropdown">
                <Collapse isOpened={isOpened}>
                  <div className="container-fluid wrapper" >

                    <div className="row">
                      <div className="col">
                        <h1>{launch.mission_name}</h1>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col">
                        <div className="text-muted mb-3">
                          {new Date(launch.date_utc).toString()}
                          {launch.success ? <div>Successful Launch! <Emoji symbol="🚀" label="Rocket."/></div> : (launch.upcoming ? <div>Upcoming Launch</div> : <div>Launch Failure</div>)}
                        </div>
                      </div>
                    </div>

                    <div className="row details">
                      <div className="col">
                        {launch.details ? <p>{launch.details}</p> : ""}
                      </div>
                    </div>

                    <div className="row">
                      <div className="col">
                        <h5>
                          Rocket Core(s)
                        </h5>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col">
                        {launchCores}
                      </div>
                    </div>

                    <div className="row">
                      <div className="col">
                        <h5>
                          Payload(s)
                        </h5>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col">
                        {payloads}
                      </div>
                    </div>

                    <div className="row">
                      <div className="col">
                        <p>
                          <button className="btn btn-dark" onClick={() => { this.toggleOpenedLaunch(launch.flight_number) }}>close</button>
                        </p>
                      </div>
                    </div>
                  </div>
                </Collapse>
              </td>
            </tr>
          </React.Fragment>
        )
      });
    } else {
      for (var i = 0; i < 20; i++) {
        launches.push(
          <tr key={i}>
            <td>
              <Loading />
            </td>
            <td>
              <Loading />
            </td>
            <td>
              <Loading />
            </td>
            <td>
              <Loading />
            </td>
            <td>
              <Loading />
              <br />
            </td>
          </tr>
        )
      }
    }

    return (
      <table className="table table-borderless text-left m-0 text-white" style={{minWidth: "50em"}}>
        <thead>
          <tr>
            <th scope="col" style={{width: "20%"}}>Countdown<br /><span className="note">(days:hours:mins:secs)</span></th>
            <th scope="col" style={{width: "20%"}}>Date/Time<br /><span className="note">(UTC{moment().format("ZZ")})</span></th>
            <th scope="col" style={{width: "20%"}}>Name</th>
            <th scope="col" style={{width: "20%"}}>Rocket</th>
            <th scope="col" style={{width: "20%"}}>Site</th>
          </tr>
        </thead>
        <tbody>
          {launches}
        </tbody>
      </table>
    )
  }
}

const mapStateToProps = state => {
  return {
    launchesFuture: state.launchData.future,
    launchpads: state.launchData.launchpads,
    rockets: state.launchData.rockets,
    payloads: state.launchData.payloads,
    cores: state.launchData.cores
  }
}

export default connect(mapStateToProps, undefined)(Future)
