import React, { Component } from 'react';
import { Route, Switch, withRouter } from "react-router-dom";
import {connect} from "react-redux";
import {launches} from "./services/fetch";

import Footer from "./Components/Global/Footer";
import Header from "./Components/Global/Header";

import Home from "./Components/Home";
import About from "./Components/About"
import Launches from "./Components/Launches"

import './App.sass';
require('bootstrap');

class App extends Component {
  constructor(props) {
    super(props);
    this.props.fetchLaunches();
  }

  updateTitle(text) {
    document.title = text + " - SpaceX Data.Info"
  }

  render() {
    return (
      <div className="d-flex flex-column text-white" style={{minHeight: "100vh"}}>
        <div className="">
          <Header />
        </div>

        <div className="d-flex flex-grow-1 align-items-center text-center">
          <Switch>
            <Route exact path="/" render={() => <Home updateTitle={(text) => this.updateTitle(text)} />} />
            <Route path="/launches" render={() => <Launches updateTitle={(text) => this.updateTitle(text)} />} />
            <Route exact path="/about" render={() => <About updateTitle={(text) => this.updateTitle(text)} />} />
          </Switch>
        </div>

        <div className="">
          <Footer />
        </div>
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  fetchLaunches: () => dispatch(launches())
});

export default withRouter(connect(undefined, mapDispatchToProps)(App));
