const LAUNCH_NEXT_UPDATE = 'LAUNCH/NEXT_UPDATE';
const LAUNCHES_PAST_UPDATE = 'LAUNCHES/PAST_UPDATE';
const LAUNCHES_FUTURE_UPDATE = 'LAUNCHES/FUTURE_UPDATE';
const LAUNCHPADS_UPDATE = "LAUNCHPADS/UPDATE";
const ROCKETS_UPDATE = "ROCKETS/UPDATE";
const PAYLOADS_UPDATE = "PAYLOADS/UPDATE";
const CORES_UPDATE = "CORES/UPDATE";

export default {
  LAUNCH_NEXT_UPDATE,
  LAUNCHES_PAST_UPDATE,
  LAUNCHES_FUTURE_UPDATE,
  LAUNCHPADS_UPDATE,
  ROCKETS_UPDATE,
  PAYLOADS_UPDATE,
  CORES_UPDATE
}
