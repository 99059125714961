import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import {BrowserRouter} from 'react-router-dom';
import store from './Redux/Store';
import {Provider} from "react-redux";
import ErrorBoundary from "./Components/ErrorBoundary";

ReactDOM.render((
	<ErrorBoundary>
		<Provider store={store} >
			<BrowserRouter>
				<App />
			</BrowserRouter>
		</Provider>
	</ErrorBoundary>
), document.getElementById('root'));
registerServiceWorker();
