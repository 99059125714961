import React, { Component } from 'react';
import Menu from "../Menu";
import "./styles.sass"

class Header extends Component {
  render() {
		return (
			<div className="header">
				<div className="container-fluid">
					<div className="row">
						<div className="col text-center">
							<h1>SpaceX Launch Countdown</h1>
						</div>
					</div>
					<div className="row">
						<div className="col">
							<Menu />
						</div>
					</div>
				</div>
			</div>
		)
  }
}
export default Header;
