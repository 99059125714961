import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Footer extends Component {
  render() {
		return (
      <div className="footer mb-1">
        <div className="container-fluid" style={{fontSize: "0.6rem"}}>
          <div className="row">
            <div className="col text-center">
              Created and maintained by <Link to="//www.charlesomer.co.uk" target="_blank" className="badge badge-light">Charles Omer</Link>.
            </div>
          </div>
          <div className="row">
            <div className="col text-center">
              Data from <Link to="//github.com/r-spacex/SpaceX-API" target="_blank" className="badge badge-light">SpaceX API</Link> <i>Under development.</i>
            </div>
          </div>
        </div>
      </div>
		)
  }
}
export default Footer;
