import {
	updateLaunchNext,
	updateLaunchesPast,
	updateLaunchesFuture,
	updateLauchpads,
	updateRockets,
	updatePayloads,
	updateCores
} from "../Redux/GlobalActions";

const launches = () => dispatch => {
	dispatch(launchNext())
	dispatch(launchesPast())
	dispatch(launchesFuture())
	dispatch(launchpads())
	dispatch(rockets())
	dispatch(payloads())
	dispatch(cores())
}

const launchNext = () => dispatch => {
	return fetch("https://spacex.zyndex.co.uk/api/get.php?type=next").then(res => res.json())
		.then(response => {
			if (response) {
				dispatch(updateLaunchNext(response));
			}
			return Promise.resolve();
	});
};

const launchesPast = () => dispatch => {
	return fetch("https://spacex.zyndex.co.uk/api/get.php?type=past").then(res => res.json())
		.then(response => {
			if (response) {
				dispatch(updateLaunchesPast(response));
			}
			return Promise.resolve();
	});
};

const launchesFuture = () => dispatch => {
	return fetch("https://spacex.zyndex.co.uk/api/get.php?type=future").then(res => res.json())
		.then(response => {
			if (response) {
				dispatch(updateLaunchesFuture(response));
			}
			return Promise.resolve();
	});
};

const launchpads = () => dispatch => {
	return fetch("https://spacex.zyndex.co.uk/api/get.php?type=launchpads").then(res => res.json())
		.then(response => {
			if (response) {
				dispatch(updateLauchpads(response));
			}
			return Promise.resolve();
	});
};

const rockets = () => dispatch => {
	return fetch("https://spacex.zyndex.co.uk/api/get.php?type=rockets").then(res => res.json())
		.then(response => {
			if (response) {
				dispatch(updateRockets(response));
			}
			return Promise.resolve();
	});
};

const payloads = () => dispatch => {
	return fetch("https://spacex.zyndex.co.uk/api/get.php?type=payloads").then(res => res.json())
		.then(response => {
			if (response) {
				dispatch(updatePayloads(response));
			}
			return Promise.resolve();
	});
};

const cores = () => dispatch => {
	return fetch("https://spacex.zyndex.co.uk/api/get.php?type=cores").then(res => res.json())
		.then(response => {
			if (response) {
				dispatch(updateCores(response));
			}
			return Promise.resolve();
	});
};

export {
	launches
}
