// Creates a reducer from an initial state and the given handlers
// Handlers are actionType => reducer functions accepting (previousState, action)
const createReducer = (initialState, handlers) => {
    return (state = initialState, action) => {
        if (handlers.hasOwnProperty(action.type)) {
            return handlers[action.type](state, action);
        }
        return state;
    };
};

const loadingActions = {
    INCREMENT: 'INCREMENT',
    DECREMENT: 'DECREMENT'
};

const checkLoadingAction = action => {
    if (!(action && action.payload && action.payload.action) ||
        !Object.keys(loadingActions)
        .map(key => loadingActions[key])
        .includes(action.payload.action)) {
        throw new Error('Invalid loading action ' + action);
    }
};

const getNewLoadingCount = (loadingCount, action) => {
    checkLoadingAction(action);
    return loadingCount + ( (action.payload.action === loadingActions.INCREMENT) ? 1 : -1);
};

export {
    createReducer,
    checkLoadingAction,
    getNewLoadingCount,
    loadingActions
};
